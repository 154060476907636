import { BehaviorSubject } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, Environment } from '@environment';
@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  originalEnv: Environment;
  environment$: BehaviorSubject<Environment>;

  constructor(@Inject(ENVIRONMENT) private env: Environment) {
    this.originalEnv = { ...this.env };
    let localEnv;
    if (this.originalEnv?.envcustomizerenabled) {
      // localEnv = JSON.parse(localStorage.getItem('envtesting'));
    } else {
      // localStorage.setItem('envtesting', null);
    }

    this.environment$ = new BehaviorSubject({ ...env, ...localEnv } || this.env);

    if (this.originalEnv?.envcustomizerenabled) {
      this.environment$.subscribe(environment => {
        // localStorage.setItem('envtesting', JSON.stringify(environment));
      });
    }
  }

  resetEnv() {
    this.environment$.next(this.originalEnv);
  }
}
